* { box-sizing: border-box; }

html {
  font-size: 16px;
}

body {
  background-color: #0000a8;
  color: #D5D5D5;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 0;
}

.App {
  overflow-y: auto;
  width: 100%;
  position: relative;
}

.App-Content {
  align-items: center;
  display: flex;
  min-height: calc(100vh - 2rem);
  height: 100%;
  padding-bottom: 2rem;
}

p {
  margin: 0;
}

h1 {
  display: flex;
  gap: 15px;
}

.container {
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.line {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.line.block {
  display: block;
}

.line label {
  cursor: pointer;
}

.line label input[type="checkbox"] {
  cursor: pointer;
  flex-shrink: 0;
}

.line.resultado {
  background-color: #00A8A8;
  border-radius: 2px;
  color: #000000;
  font-size: 1.2rem;
  padding: 15px;
  width: 100%;
}

input[type="text"] {
  background-color: #FFFF57;
  border: 0;
  border-radius: 2px;
  color: #000000;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 15px;
  width: 100%;
}

input:focus {
  border: 0;
  outline: none;
}

.small {
  color: #a8a8a8;
  font-size: 0.8rem;
  font-style: italic;
}

.App-Footer {
  background-color: #a8a8a8;
  color: #000000;
  display: flex;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;

  position: fixed;
  bottom: 0;
  height: 2rem;
}

.App-Footer .container {
  align-items: center;
  display: flex;
  height: 2rem;
  margin-bottom: 0;
}

.App-Footer a {
  color: rgb(191, 0, 0);
  text-decoration: none;
}

.App-Footer p {
  padding-bottom: 2px;
  padding-top: 2px;
}

.App-Footer a:hover {
  background-color: rgb(191, 0, 0);
  color: #F5F5F5;
}

.mb-2rem {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  body {
    font-size: 1rem;
  }

  .App-Footer .container {
    justify-content: center;
    font-size: .75rem;
  }

  .line {
    display: block;
  }
}
